import React from "react"
import Layout from "../components/layoutLogin"
import EndProcessMessage from "../components/endProcessMessage"
import SEO from "../components/seo"

const EndRegisterPage = () => (
  <Layout>
    <SEO title="Registro" />
    <EndProcessMessage message={'Te hemos enviado un correo para confirmar tu cuenta.'}/>
  </Layout>
)

export default EndRegisterPage
